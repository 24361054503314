import type { FC, PropsWithChildren } from "preact/compat";
import { CardDesignSelector } from "../CardDesignSelector";
import type { GetImageResult } from "astro";
import { activeProduct, isRingPackSelected, isRingSelected, sets } from "../model";
import { DropDown } from "@components/Ring/DropDown";
import { ringSizes } from "src/utils/constants";
import type { SizeGuideTranslates } from "@components/Ring/ChooseSizeButton";

type RingTranslates = {
  agree: string;
  "choose-size": string;
  delivery: string;
  email: {
    label: string;
    me: string;
    sent: {
      description: string;
      error: string;
      title: string;
    };
    "validation-error": string;
  };
  notify: {
    full: string;
    short: string;
  };
  "orders-available": string;
  "pre-orders-available": string;
  "recaptcha-policy": string;
  "size-guide": {
    button: string;
    description: string;
    "download-guide": string;
    "sizing-guide": string;
    title: string;
    "video-guide": string;
    ways: {
      list: {
        measure: string;
        print: string;
        use: string;
        watch: string;
      };
      title: string;
    };
  };
  "sold-out": string;
  title: string;
};

interface Props extends PropsWithChildren {
  cardsImages: Record<
    string,
    {
      images: GetImageResult[];
      preview: GetImageResult;
    }
  >;
  cardsLabelText: string;
  limitedBadgeText: string;
  ringTranslates: RingTranslates;
  sizeGuideTranslates: SizeGuideTranslates;
  buttonCloseText: string;
}

export const CardOrRing: FC<Props> = ({
  cardsImages,
  cardsLabelText,
  limitedBadgeText,
  ringTranslates,
  sizeGuideTranslates,
  buttonCloseText,
  children,
}) => {
  const handleSelectRingSku = (sku: string) => {
    isRingSelected.value = true;
    activeProduct.value =
      sets
        .peek()
        .ring.find((product) =>
          product.variants.find((el) => el.sku === sku),
        ) ?? null;
  };

  return isRingPackSelected.value ? (
    <DropDown
      name="sku"
      items={Object.values(ringSizes).map((size) => ({
        label: `!!${size.us} US!! • ${size.mm} mm`,
        id: size.id,
        isSoldOut: !size.available,
      }))}
      sizeGuideTranslates={sizeGuideTranslates}
      labelText={ringTranslates["choose-size"]}
      soldoutText={ringTranslates["sold-out"]}
      notifyTextShort={ringTranslates.notify.short}
      notifyTextFull={ringTranslates.notify.full}
      emailMeText={ringTranslates.email.me}
      emailLabelText={ringTranslates.email.label}
      emailErrorText={ringTranslates.email["validation-error"]}
      sentTitleText={ringTranslates.email.sent.title}
      sentDescriptionText={ringTranslates.email.sent.description}
      sentErrorText={ringTranslates.email.sent.error}
      buttonCloseText={buttonCloseText}
      recaptchaPolicyText={ringTranslates["recaptcha-policy"]}
      variant="small"
      onSelectSku={handleSelectRingSku}
    >
      {children}
    </DropDown>
  ) : (
    <CardDesignSelector
      images={cardsImages}
      labelText={cardsLabelText}
      limitedBadgeText={limitedBadgeText}
      variant="small"
    />
  );
};
