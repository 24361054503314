import { counter } from "@components/WalletForm/models/counter.ts";

const QUANTITY_MIN_VALUE = 1;
const QUANTITY_MAX_VALUE = 9999;

type Props = {
  maxValue?: number;
  minValue?: number;
};

export const Counter = ({
  maxValue = QUANTITY_MAX_VALUE,
  minValue = QUANTITY_MIN_VALUE,
}: Props) => {
  const handleInput = (e: Event) => {
    if (e.currentTarget instanceof HTMLInputElement) {
      const value = +e.currentTarget?.value;
      if (
        !isNaN(value) &&
        Number.isInteger(value) &&
        value > 0 &&
        value <= maxValue
      ) {
        counter.value = value;
      }
    }
  };

  const handleBlur = (e: FocusEvent) => {
    if (e.currentTarget instanceof HTMLInputElement) {
      const value = e.currentTarget?.value;
      if (!value || isNaN(+value)) {
        counter.value = minValue;
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (["Backspace", "Delete"].includes(e.code)) {
      return;
    }

    if (e.code === "ArrowDown" && counter.value > minValue) {
      return;
    }

    if (e.code === "ArrowUp" && counter.value < maxValue) {
      return;
    }

    if (e.code === "Enter") {
      e.preventDefault();
    }

    if (e.code === "Minus") {
      e.preventDefault();
    }

    if (e.code === "Digit0" && counter.value < minValue) {
      e.preventDefault();
    }

    if (isNaN(Number(e.key))) {
      e.preventDefault();
    }
  };

  return (
    <>
      <button
        type="button"
        className="checkout__quantity-button checkout__quantity-button_decrement"
        aria-label="Remove 1 set"
        onClick={() => {
          if (counter.value > minValue) {
            counter.value -= 1;
          }
        }}
        disabled={counter.value === maxValue}
      ></button>
      <input
        className="checkout__quantity-value"
        value={counter.value}
        type="number"
        aria-label="Quantity of sets"
        onInput={handleInput}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />
      <button
        type="button"
        className="checkout__quantity-button checkout__quantity-button_increment"
        aria-label="Add 1 set"
        onClick={() => {
          if (counter.value < maxValue) {
            counter.value += 1;
          }
        }}
        disabled={counter.value === maxValue}
      ></button>
    </>
  );
};

export default Counter;
